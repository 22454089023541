import { styled } from "styled-components";


export const WrapperError = styled.div`
    width: 100%;
    height: 80vh !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    h2{
        margin: 0;
        margin-top: 15px;
        margin-bottom: 5px;
        font-size: 35px;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        color: gray;
        text-align: center;
    }
    p{
        color: gray;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }
    p a{
        color:  rgb(254 150 50);
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;   
    }
    button{
        background-color: rgb(254, 150, 50);
        border: none;
        color: white;
        font-size: 18px;
        padding: 10px 30px;
        border-radius: 12px;
        cursor: pointer;
        margin-top: 10px;
    }
`